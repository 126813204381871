<template>
    <div class="weekly-free-games-wrapper main-wrapper">
        <div>
            <img src="@/assets/img/free_title.png" width="100%" alt="">
        </div>
        <div class="hot-game">
            <div class="title-box">
                <span class="text-strike">本周免费游戏专区</span>
            </div>
            <ul class="game-box">
                <li v-for="(item, index) in thisWeekGameList" :key="index"
                    @click="toFreetoplay(item.gameInfoEntity.Name, item.ProductID)">
                    <div class="icon">
                        免费玩<br />
                        价值：￥{{ item.gameInfoEntity.Originalprice && item.gameInfoEntity.Originalprice.toString().split(".")[0] }}
                    </div>
                    <div v-if="item && item.gameInfoEntity">
                        <div :style="{
                            height: '100px',
                            background: `url(${item.gameInfoEntity.PicList[0]}?x-oss-process=image/resize,h_106)`,
                            backgroundSize: '100% 100px',
                            borderRadius: '6px 6px 0 0',
                        }"></div>
                        <h4>{{ item.gameInfoEntity.Name }}</h4>

                        <div class="free-time">
                            本周限时免费
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="hot-game">
            <div class="title-box">
                <span class="text-strike">下周免费游戏专区</span>
            </div>
            <ul class="game-box">
                <li :class="{ before: index > 1 }" v-for="(item, index) in nextWeekGameList" :key="index"
                    @click.stop="index > 1 ? ()=>{} : toFreetoplay(item.gameInfoEntity.Name, item.ProductID)">
                    <div :class="index > 1 ? 'icon2' : 'icon'">
                        免费玩<br />
                        价值：￥{{ item.gameInfoEntity.Originalprice && item.gameInfoEntity.Originalprice.toString().split(".")[0] }}
                        <img class="coming-soon" v-if="index > 1" src="@/assets/img/coming_soon.png" width="129px"
                            height="129px" alt="">
                    </div>
                    <div v-if="item && item.gameInfoEntity">
                        <div :style="{
                            height: '100px',
                            background: `url(${item.gameInfoEntity.PicList[0]}?x-oss-process=image/resize,h_106)`,
                            backgroundSize: '100% 100px',
                            borderRadius: '6px 6px 0 0',
                        }"></div>
                        <h4>{{ item.gameInfoEntity.Name }}</h4>

                        <div class="Ended">
                            {{ nextWeekDate }}免费
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="hot-game">
            <div class="title-box">
                <span class="text-strike">上期免费游戏专区</span>
            </div>
            <ul class="game-box">
                <li class="before" v-for="(item, index) in lastWeekGameList" :key="index"
                    @click="toFreetoplay(item.gameInfoEntity.Name, item.ProductID)">
                    <div class="icon2">
                        免费玩<br />
                        价值：￥{{ item.gameInfoEntity.Originalprice && item.gameInfoEntity.Originalprice.toString().split(".")[0] }}
                    </div>
                    <div v-if="item && item.gameInfoEntity">
                        <div :style="{
                            height: '100px',
                            background: `url(${item.gameInfoEntity.PicList[0]}?x-oss-process=image/resize,h_106)`,
                            backgroundSize: '100% 100px',
                            borderRadius: '6px 6px 0 0',
                        }"></div>
                        <h4>{{ item.gameInfoEntity.Name }}</h4>

                        <div class="Ended">
                            已结束
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
  
<script>
import {
    GetHomePageGames,
} from "@/api";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "Home",
    computed: {
        ...mapGetters(["isLogin", "netBarId", "netBarVIP", "userInfo"]),
        nextWeekDate() {
            // 获取当前日期
            const now = moment();
            // 获取下周一的日期
            const nextMonday = now.clone().add(1, 'weeks').day('Monday');
            const nextSunday = now.clone().add(2, 'weeks').day('Sunday');
            return nextMonday.format('MM-DD').replace('-', '月') + "-" + nextSunday.format('MM-DD').replace('-', '月');
        }
    },
    data() {
        return {
            thisWeekGameList: [],
            lastWeekGameList: [],
            nextWeekGameList: [],
        };
    },
    mounted() {
        // 推荐游戏
        this._GetHomePageGames(3);
        this._GetHomePageGames(10);

        this.$statistic(["A", "周免活动", "页面", "访问量"]);
    },
    methods: {
        //获取平台、网吧用户和游戏数据
        async _GetHomePageGames(navigationType) {
            let params = {
                type: 2,
                navigationType: navigationType,
            };
            let res = await GetHomePageGames(params);
            if (res.StatusCode == 200) {
                let data = res.Object;
                // this.gameList = data.slice(0, 3);
                if (navigationType == 3) {
                    this.thisWeekGameList = data.slice(3, 8);
                }
                if (navigationType == 10) {
                    this.lastWeekGameList = data.slice(0, 5);
                    this.nextWeekGameList = data.slice(5, 10);
                }
            }
        },
        // 热门游戏跳转
        toFreetoplay(Name, ProductID) {
            this.$router.push(`/gameDetail/${ProductID}`);
        },
        beautySub(str, len) {
            var reg = /[\u4e00-\u9fa5]/g, //匹配中文
                slice = str.substring(0, len),
                chineseCharNum = ~~(slice.match(reg) && slice.match(reg).length),
                realen = slice.length * 2 - chineseCharNum;
            return str.substr(0, realen) + (realen < str.length ? "..." : "");
        },
    },
};
</script>
  
<style lang="scss" scoped>
.weekly-free-games-wrapper {
    position: relative;

    .hot-game {
        .title-box {
            height: 52px;
            line-height: 52px;
            font-size: 28px;
            font-weight: bold;
            text-align: center;

            @include font_col3();

            .text-strike {
                position: relative;
                font-size: 24px;
                font-weight: bold;
                color: #333;
            }

            .text-strike::before,
            .text-strike::after {
                content: '';
                position: absolute;
                top: 50%;
                width: 40px;
                height: 2px;
                background: #333;
            }

            .text-strike::before {
                left: -20%;
                transform: translateX(-50%);
            }

            .text-strike::after {
                right: -20%;
                transform: translateX(50%);
            }
        }

        .game-box {
            display: flex;
            justify-content: space-between;

            li {
                width: 190px;
                height: 150px;
                border-radius: 6px;
                box-sizing: border-box;
                text-align: center;
                @include item_bg_col();
                position: relative;

                .icon {
                    width: 111px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    position: absolute;
                    top: -5px;
                    left: 5px;
                    color: #fff;
                    font-size: 12px;
                    line-height: 14px;
                    background: url('../assets/img/left_icon1.png') no-repeat;
                }

                .icon2 {
                    width: 111px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    position: absolute;
                    top: -5px;
                    left: 5px;
                    color: #fff;
                    font-size: 12px;
                    line-height: 14px;
                    background: url('../assets/img/left_icon2.png') no-repeat;
                }

                .coming-soon {
                    position: absolute;
                    top: 15.5px;
                    left: 30.5px;
                    z-index: 99;
                }

                h4 {
                    font-size: 15px;
                    line-height: 24px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @include font_col3();

                    .cloud-archiving {
                        padding: 2px 5px;
                        margin-left: 10px;
                        background: $linear-col;
                        border-radius: 4px;
                        color: $white-col;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

                .free-time {
                    width: 180px;
                    padding: 3px 10px;
                    margin: 0 auto;
                    border-radius: 4px;
                    color: #ff682d;
                    background: transparent;
                    border: 1px solid #ff682d;
                    box-sizing: border-box;
                }

                .Ended {
                    width: 180px;
                    padding: 3px 10px;
                    margin: 0 auto;
                    border-radius: 4px;
                    color: #333;
                    background: transparent;
                    border: 1px solid #333;
                    box-sizing: border-box;
                }
            }

            li.before::before {
                content: '';
                position: absolute;
                top: -5px;
                left: 0;
                width: 100%;
                height: 155px;
                border-radius: 6px;
                background: rgba(0, 0, 0, 0.5);
                z-index: 99;
            }

            li:hover {
                transform: translateY(-5px);
                transition: 0.2s ease;
                cursor: pointer;
                box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);


                .free-time {
                    color: #fff;
                    background: linear-gradient(90deg, #FF9A68 0%, #FF6868 100%);
                }
            }
        }
    }
}
</style>
  