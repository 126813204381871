var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"weekly-free-games-wrapper main-wrapper"},[_vm._m(0),_c('div',{staticClass:"hot-game"},[_vm._m(1),_c('ul',{staticClass:"game-box"},_vm._l((_vm.thisWeekGameList),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.toFreetoplay(item.gameInfoEntity.Name, item.ProductID)}}},[_c('div',{staticClass:"icon"},[_vm._v(" 免费玩"),_c('br'),_vm._v(" 价值：￥"+_vm._s(item.gameInfoEntity.Originalprice && item.gameInfoEntity.Originalprice.toString().split(".")[0])+" ")]),(item && item.gameInfoEntity)?_c('div',[_c('div',{style:({
                        height: '100px',
                        background: ("url(" + (item.gameInfoEntity.PicList[0]) + "?x-oss-process=image/resize,h_106)"),
                        backgroundSize: '100% 100px',
                        borderRadius: '6px 6px 0 0',
                    })}),_c('h4',[_vm._v(_vm._s(item.gameInfoEntity.Name))]),_c('div',{staticClass:"free-time"},[_vm._v(" 本周限时免费 ")])]):_vm._e()])}),0)]),_c('div',{staticClass:"hot-game"},[_vm._m(2),_c('ul',{staticClass:"game-box"},_vm._l((_vm.nextWeekGameList),function(item,index){return _c('li',{key:index,class:{ before: index > 1 },on:{"click":function($event){$event.stopPropagation();index > 1 ? function (){} : _vm.toFreetoplay(item.gameInfoEntity.Name, item.ProductID)}}},[_c('div',{class:index > 1 ? 'icon2' : 'icon'},[_vm._v(" 免费玩"),_c('br'),_vm._v(" 价值：￥"+_vm._s(item.gameInfoEntity.Originalprice && item.gameInfoEntity.Originalprice.toString().split(".")[0])+" "),(index > 1)?_c('img',{staticClass:"coming-soon",attrs:{"src":require("@/assets/img/coming_soon.png"),"width":"129px","height":"129px","alt":""}}):_vm._e()]),(item && item.gameInfoEntity)?_c('div',[_c('div',{style:({
                        height: '100px',
                        background: ("url(" + (item.gameInfoEntity.PicList[0]) + "?x-oss-process=image/resize,h_106)"),
                        backgroundSize: '100% 100px',
                        borderRadius: '6px 6px 0 0',
                    })}),_c('h4',[_vm._v(_vm._s(item.gameInfoEntity.Name))]),_c('div',{staticClass:"Ended"},[_vm._v(" "+_vm._s(_vm.nextWeekDate)+"免费 ")])]):_vm._e()])}),0)]),_c('div',{staticClass:"hot-game"},[_vm._m(3),_c('ul',{staticClass:"game-box"},_vm._l((_vm.lastWeekGameList),function(item,index){return _c('li',{key:index,staticClass:"before",on:{"click":function($event){return _vm.toFreetoplay(item.gameInfoEntity.Name, item.ProductID)}}},[_c('div',{staticClass:"icon2"},[_vm._v(" 免费玩"),_c('br'),_vm._v(" 价值：￥"+_vm._s(item.gameInfoEntity.Originalprice && item.gameInfoEntity.Originalprice.toString().split(".")[0])+" ")]),(item && item.gameInfoEntity)?_c('div',[_c('div',{style:({
                        height: '100px',
                        background: ("url(" + (item.gameInfoEntity.PicList[0]) + "?x-oss-process=image/resize,h_106)"),
                        backgroundSize: '100% 100px',
                        borderRadius: '6px 6px 0 0',
                    })}),_c('h4',[_vm._v(_vm._s(item.gameInfoEntity.Name))]),_c('div',{staticClass:"Ended"},[_vm._v(" 已结束 ")])]):_vm._e()])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/free_title.png"),"width":"100%","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('span',{staticClass:"text-strike"},[_vm._v("本周免费游戏专区")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('span',{staticClass:"text-strike"},[_vm._v("下周免费游戏专区")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('span',{staticClass:"text-strike"},[_vm._v("上期免费游戏专区")])])}]

export { render, staticRenderFns }